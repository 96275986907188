<script>
export default {
  data() {
    return {
      opening_balances: [],
      OpeningBalances: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchOpeningBalances: null,
      OpeningBalancesSearchMode: false,
      level_three: [],
      balance: {},
      // appprices:[]
    };
  },
  methods: {
    addOpeningBalances() {
      console.log(this.balance.level_three_chart_of_account_id);
      this.http.post("opening-balances", this.balance).then(() => {
        this.balance = {};
        this.get(this.page);
      });
    },
    search() {
      this.OpeningBalancesSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("opening-balances/search", { search: this.searchOpeningBalances })
        .then((res) => {
          this.opening_balances = res.data;
        });
    },
    cancelappsearchMode() {
      this.searchOpeningBalances = "";
      this.OpeningBalancesSearchMode = false;
      this.get(this.page);
    },
    getOpeningBalances() {
      this.http.get("opening-balances").then((res) => {
        this.opening_balances = res.data;
      });
    },
    deleteOpeningBalances(app) {
      var data = {
        title: "popups. deletesms",
        template: `
          <h5>Are you sure you want to delete ?</h5> 
   
        `,
        buttons: [
          {
            text: "Delete",
            closer: true,
            color: "danger",
            handler: () => {
              console.log(app);
              this.http.delete("opening-balances", app.id).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "Cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    getLevelThree() {
      this.http.get("level-three-chart-of-accounts-copy-1").then((res) => {
        this.level_three = res.data;
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("opening-balances/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.opening_balances = res.data;
        });
    },
  },
  created() {
    this.get(1);
    // this.getOpeningBalances()
    this.getLevelThree();
  },
};
</script>

<template>
  <button
    type="button"
    data-bs-toggle="modal"
    data-bs-target="#addModal"
    class="btn btn-light float-end mb-4"
  >
    <span
      class="bx bxs-plus-circle float-end fa-2x"
      style="color: #2a3042 !important"
    ></span>
  </button>

  <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
    <thead>
      <tr
        class="text-light text-center"
        style="background-color: #2a3042 !important"
      >
        <th scope="col">{{ $t("opening_balances.id") }}</th>
        <th scope="col">
          {{ $t("opening_balances.level_three_chart_of_account") }}
        </th>
        <th scope="col">{{ $t("opening_balances.amount") }}</th>
        <th scope="col">{{ $t("opening_balances.type") }}</th>
        <th scope="col">{{ $t("opening_balances.year") }}</th>
        <th scope="col">{{ $t("opening_balances.created") }}</th>
        <th scope="col">{{ $t("opening_balances.updated") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(app, index) in opening_balances"
        :key="app"
        class="text-center"
      >
        <td>{{ index + 1 }}</td>
        <td>{{ app?.level_three_chart_of_account?.name }}</td>
        <td>{{ parseFloat(app.amount).toLocaleString() }}</td>
        <td>{{ app.type }}</td>
        <td>{{ app?.year?.split("-")[0] }}</td>
        <td>{{ app?.created.split("T")[0] }}</td>
        <td>{{ app?.updated.split("T")[0] }}</td>
      </tr>
    </tbody>
  </table>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <!--   Apps  Pagination     -->
  <div v-if="tot_pages == 20">
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <!--end-->

  <!--Start addModal-->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="addOpeningBalances()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.addopeningbalance") }}
            </h5>
            <button
              style="margin: 0"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3 mt-4">
              <label for="exampleInputEmail1" class="form-label">
                {{ $t("opening_balances.level_three_chart_of_account") }}
              </label>
              <select
                class="form-select"
                aria-label="Default select example"
                v-model="balance.level_three_chart_of_account_id"
              >
                <option v-for="i in this.level_three" :key="i" :value="i.id">
                  {{ i.name }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label">
                {{ $t("opening_balances.amount") }}
              </label>
              <input
                type="text"
                v-model="balance.amount"
                class="form-control"
                id="exampleInputPassword1"
              />
            </div>

            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label">
                {{ $t("opening_balances.type") }}
              </label>
              <input
                type="text"
                v-model="balance.type"
                class="form-control"
                id="exampleInputPassword1"
              />
            </div>
            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label">
                {{ $t("opening_balances.year") }}
              </label>
              <input
                type="date"
                v-model="balance.year"
                class="form-control"
                id="exampleInputPassword1"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              {{ $t("popups.add") }}
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End addModal-->
</template>
